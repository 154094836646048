<template>
  <div>
    <BreadCrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs" />

    <v-card class="mt-2">
      <v-card-title>
        Solicitações de professores pendentes {{ formattedInstitutionName }}
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="users"
          disable-pagination
          hide-default-footer
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="mr-2"
              title="Aceitar"
              @click="accept(item.UserId)"
              color="success"
              icon
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn
              class="mr-2"
              title="Recusar"
              @click="decline(item.UserId)"
              color="error"
              icon
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="successDialog" max-width="400">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Operação realizada com sucesso</h3>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="successDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import BreadCrumbs from "@/components/template/Breadcrumbs.vue";

export default {
  components: {
    BreadCrumbs,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: null,
    },
  },
  computed: {
    formattedInstitutionName() {
      return this.institutionName ? `(${this.institutionName})` : "";
    },
    institutionId() {
      return this.$route.params.id;
    },
  },
  data: () => ({
    headers: [
      { text: "Nome", value: "fullName" },
      { text: "Email", value: "email" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    successDialog: false,
    users: [],
    institutionName: null,
  }),
  methods: {
    async loadPendingRequests() {
      try {
        this.loading = true;

        const response = await this.$axios.get(
          `/admin/solicitacoes/buscar/${this.institutionId}`
        );

        this.setUsers(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setUsers(data) {
      const cpData = { ...data };

      this.users = cpData.requests.map((u) => {
        return { ...u, fullName: `${u.firstName} ${u.lastName}` };
      });

      this.institutionName = cpData.institutionName;
    },
    async accept(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Confirmação",
          `Aceitar esta solicitação?`
        );

        if (!confirm) return;

        this.loading = true;

        await this.$axios.put(
          `/admin/instituicoes/${this.institutionId}/usuario/${id}/solicitacoes/aceitar`
        );

        this.deleteRequestFromTable(id);
        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async decline(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente recusar esta solicitação?`
        );

        if (!confirm) return;

        this.loading = true;

        await this.$axios.put(
          `/admin/instituicoes/${this.institutionId}/usuario/${id}/solicitacoes/rejeitar`
        );

        this.deleteRequestFromTable(id);
        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    deleteRequestFromTable(id) {
      const index = this.users.findIndex((p) => p.id === id);

      this.users.splice(index, 1);
    },
    redirectToLinkUsersPage() {
      this.$router.push(
        `/admin/instituicao/${this.institutionId}/usuarios/vincular`
      );
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  created() {
    this.loadPendingRequests();
  },
};
</script>

<style></style>
